<template>
  <div class="cpt-MMC_Gimbal_FN3" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">网枪</div>
      </div>
      <div class="close" @click="$emit('close')">{{$t('operation.Close')}}</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">保险：</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini" />
          </div>
        </div>
        <div class="form-item mt16">
          <img src="@/assets/images/observe/011.svg" alt="" />
          <div class="launch">
            <img src="~@/assets/images/mount/launch.png" alt="" @click="handle_laser_shine_ctrl" />
            <div class="label-box">发射</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_FN3 } from '../utils';
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timer: null,
      radio: 1,
      safety_switch_state: true
    };
  },
  mounted() {},
  methods: {
    handle_laser_shine_ctrl(value) {
      if (this.safety_switch_state) {
        this.$message.warning('请关闭保险');
      } else {
        const buff = MMC_Gimbal_FN3.laser_shine_ctrl(1);
        this.commit_directive(buff);
        this.$message.success('发射成功');
        this.safety_switch_state = !this.safety_switch_state;
      }
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit('directive', buffer);
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_FN3 {
  position: absolute;
  // bottom: 0;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 240px;
  // height: 218px;

  box-sizing: border-box;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .bd {
    display: flex;
    .form-wrap {
      padding: 16px 0;
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .form-item {
        display: flex;
        align-items: center;
        .label-box {
          color: #cbd0eb;
        }
        .input-box {
          display: flex;
          align-items: center;
          .icon-box {
            [class^='el-icon-'] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }
      .launch {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
